import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SchemeTypesState } from './scheme-types.state';

export const schemeTypesFeatureKey = 'schemeTypes';
export const selectSchemeTypeFeature =
  createFeatureSelector<SchemeTypesState>(schemeTypesFeatureKey);

export const selectAllSchemeTypes = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.schemeTypes
);
export const selectLoadingSchemeTypes = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.loadingSchemeTypes
);
export const selectSchemeType = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.schemeType
);
export const selectLoadingSchemeType = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.loadingSchemeType
);
export const selectSchemeTypeUpdated = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.schemeTypeUpdated
);
export const selectSchemeTypeDeleted = createSelector(
  selectSchemeTypeFeature,
  (schemeTypesState: SchemeTypesState) => schemeTypesState.schemeTypeDeleted
);
